import { api, i18n } from "@/config"
import { map, isEmpty, sumBy, transform } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { handleUpdateItemsError } from "@/config/store/matching/shared"
import { showToast } from "@/helpers/toasts"
import { SHOPS_MAX_COUNT } from "@/config/constants"
import { currentTimestamp } from "../../StoreItemsModule/helpers"

const isTotalCountExceed = (items, itemsDeleted, pagination) => {
  const total = sumBy(items, item => Number(!isEmpty(item._addedAt))) + pagination.total_count - itemsDeleted.length

  return total > SHOPS_MAX_COUNT
}

const prepareSourcesCarsParams = ({ itemsAdded = [], itemsDeleted = [], itemsUpdated = [] }) => {
  return transform(
    {
      deleted: itemsDeleted,
      created: itemsAdded,
      updated: itemsUpdated
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, ({ id, shop, car_class }) => {
          return {
            id,
            shop_id: shop.id,
            car_class: {
              id: car_class.id,
              name: car_class.name,
              dp: car_class.dp,
              sync: car_class.sync,
              inbound: car_class.inbound,
              sources_car_class_ids: map(car_class.sources_car_classes, ({ id }) => id)
            }
          }
        })
      }
    },

    {}
  )
}

const prepareCarClass = () => {
  return {
    id: null,
    dp: false,
    inbound: false,
    name: null,
    sources_car_classes: [],
    sync: false
  }
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters,
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }

    const response = await api.get(baseURI, { params })
    const { items, meta } = response.data.data

    const customItems = items.map(sourcesCar => ({ ...sourcesCar, _addedAt: null, isOpened: false }))

    commit("SET_ITEMS", customItems)
    commit("SET_PAGINATION_DATA", meta.pagination)
    commit("SET_ERRORS_INFO", meta.errors_info)
  },

  UPDATE_ITEMS: async (
    {
      state: { itemsAdded, itemsDeleted, filters, pagination, sorting, items: exItems },
      getters: { itemsUpdated },
      commit
    },
    customParams = {}
  ) => {
    const carParams = prepareSourcesCarsParams({ itemsAdded, itemsDeleted, itemsUpdated })

    try {
      if (isEmpty(carParams)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const allItems = [...itemsAdded, ...exItems]
      const response = await api.put(baseURI, { cars: carParams, ...params })
      const { items, meta } = response.data.data

      const customItems = items.map(item => {
        return {
          ...item,
          _addedAt: null,
          isOpened: allItems.find(({ id }) => item.id === id)?.isOpened
        }
      })

      commit("SET_ITEMS", customItems)
      commit("SET_PAGINATION_DATA", meta.pagination)
      commit("SET_ERRORS_INFO", meta.errors_info)
      commit("SET_INVALID_ITEM_IDS", [])
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
    } catch (error) {
      handleUpdateItemsError({ error, commit, itemsUpdated, itemsDeleted, allowResetItemsDeleted: false })
    }
  },

  ADD_ITEM({ state: { items, itemsDeleted, pagination }, commit }) {
    if (isTotalCountExceed(items, itemsDeleted, pagination)) {
      return showToast({ text: i18n.t("shop_matching.reach_max_limit_error", { num: SHOPS_MAX_COUNT }) })
    }

    const item = {
      id: null,
      name: null,
      expired: false,
      inactive: false,
      active: true,
      _addedAt: `t_${currentTimestamp()}`,
      isOpened: false,
      car_class: { ...prepareCarClass() },
      shop: { id: null, name: null }
    }

    commit("ADD_ITEM", { item, prepend: true })
  },

  UPDATE_ITEM_BY_INDEX({ commit }, { item, index }) {
    commit("SET_ITEM_BY_INDEX", { item, index })
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value, ...otherParams }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS", { ...state.filters, ...otherParams })
  },

  UPDATE_ADDED_ITEM_BY_INDEX({ commit }, { item, index }) {
    commit("SET_ADDED_ITEM_BY_INDEX", { item, index })
  },

  DELETE_ITEM_BY_INDEX({ commit }, index) {
    commit("DELETE_ITEM_BY_INDEX", index)
  },

  DELETE_ADDED_ITEM_BY_INDEX({ commit }, index) {
    commit("DELETE_ADDED_ITEM_BY_INDEX", index)
  }
})
