<template lang="pug">
  .matchings(:class="{ loading }")
    BAlert(
      show
      variant="warning"
    ) {{ $t("shops_sources_car_matching.change_source_warning") }}
    BAlert(
      v-if="expiredCarsCount > 0"
      show
      variant="danger"
      :title="$t('shops_sources_car_matching.car_period_has_expired')"
    )
      | {{ $t('shops_sources_car_matching.car_period_has_expired', { count: expiredCarsCount }) }}
    BAlert(
      v-if="inactiveCarsCount > 0"
      show
      variant="danger"
      :title="$t('shops_sources_car_matching.car_with_errors')"
    )
      | {{ $t('shops_sources_car_matching.car_with_errors', { count: inactiveCarsCount }) }}
    TopBar(
      :allow-save="allowSave"
      :is-cars-with-alerts="onlyCarsWithAlerts"
      @toggle-cars-with-alerts="toggleCarsWithAlertCars"
      @add-new="handleAddNew"
      @save="updateItems"
      @search="onHandleSearch"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      ShopsSourcesCarTable(
        :items="items"
        :shops="shops"
        :items-added="itemsAdded"
        :items-deleted="itemsDeleted"
        :items-updated="itemsUpdated"
        :sorting-data="sorting"
        :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
        :selected-sources-car-classes="selectedSourcesCarClassesByOtas"
        :sources-car-classes-loading="sourcesCarClassesLoading"
        :sources-cars-loading="sourcesCarsLoading"
        :sources-cars="sourcesCars"
        :selected-sources-cars="selectedSourcesCars"
        :invalid-item-ids="invalidItemIds"
        :update-existing-item="updateItemByIndex"
        :delete-existing-item="deleteItemByIndex"
        :update-added-item="updateAddedItemByIndex"
        :delete-added-item="deleteAddedItemByIndex"
        :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
        :add-selected-sources-car-class="addSelectedSourcesCarClass"
        :remove-selected-sources-car="removeSelectedSourcesCar"
        :add-selected-sources-car="addSelectedSourcesCar"
        @sorting="handleChangeSorting"
      )
      AppPagination(
        :total="itemsPagination.total_count"
        :per-page="itemsPagination.per_page"
        :current-page="itemsPagination.current_page"
        @change-pagination-data="handleChangePagination"
      )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withMatchingPageMethods from "@/mixins/matching_page/withIndexMethods"

  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import shopSourcesCarMatchingModule from "@/config/store/matching/shops_sources_car"
  import sourcesCarClassesModule from "@/config/store/sources/car_classes"
  import sourcesCarsModule from "@/config/store/sources/cars"

  // misc
  import { every, isEmpty, reduce } from "lodash-es"
  import { mapGetters } from "vuex"

  import { SERIALIZER_VIEW_EXTENDED } from "@/config/constants"

  const sourcesCarMatchingsMixin = withStoreModule(shopSourcesCarMatchingModule, {
    resetState: true,
    name: "sourcesCarMatching",
    readers: {
      items: "items",
      errorsInfo: "errorsInfo",
      invalidItemIds: "invalidItemIds",
      itemsAdded: "itemsAdded",
      itemsDeleted: "itemsDeleted",
      itemsLoading: "loading",
      itemsPagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchItemsAction: "FETCH_ITEMS",
      addItem: "ADD_ITEM",
      updateItems: "UPDATE_ITEMS",
      updateItemByIndex: "UPDATE_ITEM_BY_INDEX",
      updateAddedItemByIndex: "UPDATE_ADDED_ITEM_BY_INDEX",
      deleteItemByIndex: "DELETE_ITEM_BY_INDEX",
      deleteAddedItemByIndex: "DELETE_ADDED_ITEM_BY_INDEX",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setInvalidItemsIds: "SET_INVALID_ITEM_IDS",
      resetAddedItems: "RESET_ITEMS_ADDED",
      resetDeletedItems: "RESET_ITEMS_DELETED",
      resetItems: "RESET_ITEMS",
      setSorting: "SET_SORTING"
    },
    getters: {
      itemsUpdated: "itemsUpdated"
    }
  })

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    resetState: true,
    name: "shopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const sourcesCarClassesMixin = withStoreModule(sourcesCarClassesModule, {
    resetState: true,
    name: "sourcesCarClasses",
    readers: {
      sourcesCarClassesLoading: "loading",
      selectedSourcesCarClasses: "selectedItems",
      selectedSourcesCarClassesLoading: "selectedItemsLoading"
    },
    getters: { sourcesCarClassesByOtaId: "groupedByOta" },
    actions: {
      fetchSourcesCarClasses: "FETCH_ITEMS",
      fetchSelectedSourcesCarClasses: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesCarClass: "ADD_SELECTED_ITEM",
      removeSelectedSourcesCarClass: "REMOVE_SELECTED_ITEM"
    }
  })

  const sourcesCarsMixin = withStoreModule(sourcesCarsModule, {
    resetState: true,
    name: "sourcesCars",
    readers: {
      sourcesCarsLoading: "loading",
      sourcesCars: "items",
      selectedSourcesCars: "selectedItems",
      selectedSourcesCarsLoading: "selectedItemsLoading"
    },
    actions: {
      fetchSourcesCars: "FETCH_ITEMS",
      fetchSelectedSourcesCars: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesCar: "ADD_SELECTED_ITEM",
      removeSelectedSourcesCar: "REMOVE_SELECTED_ITEM"
    }
  })

  export default {
    data() {
      return {
        onlyCarsWithAlerts: false
      }
    },

    computed: {
      ...mapGetters(["currentOrganization", "otasWithoutNicolas"]),

      selectedSourcesCarClassesByOtas({ selectedSourcesCarClasses, otasWithoutNicolas }) {
        return reduce(
          otasWithoutNicolas,
          (obj, ota) => {
            obj[ota.id] = selectedSourcesCarClasses[ota.id] || []

            return obj
          },
          {}
        )
      },

      loading({
        itemsLoading,
        carClassesLoading,
        selectedSourcesCarClassesLoading,
        sourcesCarClassesLoading,
        selectedSourcesCarsLoading,
        sourcesCarsLoading
      }) {
        return (
          itemsLoading ||
          carClassesLoading ||
          selectedSourcesCarClassesLoading ||
          sourcesCarClassesLoading ||
          selectedSourcesCarsLoading ||
          sourcesCarsLoading
        )
      },

      allItems() {
        return [...this.itemsAdded, ...this.items]
      },

      allowSaveCarClass() {
        const carClasses = this.allItems.flatMap(item => item.car_class)

        return every(carClasses, ({ name }) => !isEmpty(name))
      },

      isUnsavedItems() {
        return !isEmpty(this.itemsAdded) || !isEmpty(this.itemsDeleted) || !isEmpty(this.itemsUpdated)
      },

      allowSave() {
        return this.allowSaveCarClass && this.isUnsavedItems
      },

      expiredCarsCount({ itemsAdded, errorsInfo, itemsDeleted }) {
        const addedExpired = itemsAdded.filter(sourcesCar => sourcesCar.expired).length
        const deletedExpired = itemsDeleted.filter(sourcesCar => sourcesCar.expired).length

        return +errorsInfo.expired + addedExpired - deletedExpired
      },

      inactiveCarsCount({ itemsAdded, errorsInfo, itemsDeleted }) {
        const addedInactive = itemsAdded.filter(sourcesCar => sourcesCar.inactive).length
        const deletedInactive = itemsDeleted.filter(sourcesCar => sourcesCar.inactive).length

        return +errorsInfo.inactive + addedInactive - deletedInactive
      },

      itemParams() {
        return {
          serializer_view: SERIALIZER_VIEW_EXTENDED,
          only_with_errors: this.onlyCarsWithAlerts
        }
      }
    },

    components: {
      TopBar: () => import("@/pages/ShopsSourcesCarMatching/TopBar"),
      ShopsSourcesCarTable: () => import("@/pages/ShopsSourcesCarMatching/ShopsSourcesCarTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      sourcesCarMatchingsMixin,
      sourcesCarClassesMixin,
      sourcesCarsMixin,
      shopMatchingMixin,
      withConfirmation,
      withMatchingPageMethods
    ],

    mounted() {
      this.fetchItems()
      this.fetchShops({ pagination: { _disabled: true }, serializer_view: "with_sources_cars_and_car_classes" })
      this.fetchSelectedSourcesCarClasses()
      this.fetchSourcesCarClasses()
      this.fetchSourcesCars()
      this.fetchSelectedSourcesCars()
    },

    watch: {
      isUnsavedItems(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    methods: {
      fetchItems() {
        this.fetchItemsAction(this.itemParams)
      },

      handleChangePagination(paginationData) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changePagination(paginationData)
        })
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.itemsPagination, ...paginationData })
        this.fetchItems()
      },

      handleChangeSorting(newSorting) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changeSorting(newSorting)
        })
      },

      changeSorting(newSorting) {
        this.setSorting(newSorting)
        this.setPagination({ ...this.itemsPagination, current_page: 1 })
        this.fetchItems()
      },

      toggleCarsWithAlertCars() {
        this.onlyCarsWithAlerts = !this.onlyCarsWithAlerts
        this.fetchItems()
      },

      onHandleSearch(value) {
        this.applySearch({ ...value, ...this.itemParams })
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .matchings
    .table-wrapper
      position: relative

    &.error
      border: 1px solid $default-red
      color: $default-red
</style>
